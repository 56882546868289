<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Surat Pindah Berkas</b>
        <span class="pull-right"> </span>
      </h3>
      <hr />
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <form @submit.prevent="submit" class="form-horizontal">
            <div class="form-body">
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group row">
                    <label
                      :class="[
                        'control-label',
                        { 'text-right': !isMobile },
                        'col-md-3',
                      ]"
                    >
                      Folder
                    </label>
                    <div class="col-md-8">
                      {{ state.detail.folder }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <div class="form-group row">
                    <label
                      :class="[
                        'control-label',
                        { 'text-right': !isMobile },
                        'col-md-3',
                      ]"
                    >
                      Pindahkan Berkas ke
                    </label>
                    <div
                      :class="['col-md-8', { invalid: isInvalidNamaFolder }]"
                    >
                      <Multiselect
                        v-model="payload.namafolder"
                        label="text"
                        track-by="value"
                        placeholder="Pilih Klasifikasi Sekunder"
                        open-direction="bottom"
                        :options="dropdownNamaFolder.namafolderList"
                        :multiple="false"
                        :searchable="true"
                        :value="dropdownNamaFolder.value"
                        :loading="dropdownNamaFolder.isLoadingNamaFolder"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :max-height="600"
                        :show-no-results="true"
                        :hide-selected="true"
                        @close="onTouchNamaFolder"
                        @input="onChangeNamaFolder"
                        @search-change="namafolder"
                      >
                        <span slot="noResult">Oops! No Data found.</span>
                      </Multiselect>
                      <label
                        class="typo__label from__label"
                        v-show="isInvalidNamaFolder"
                        >Must have at least one value</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="form-actions">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-offset-3 col-md-9">
                      <button
                        type="submit"
                        class="btn btn-success"
                        title="Submit"
                      >
                        Submit</button
                      >&nbsp;
                      <button
                        type="button"
                        @click="goBack()"
                        class="btn btn-inverse"
                        title="Cancel"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div></div>
        </div>
      </div>
    </div>
    <!-- {{ this.$route.params.id }} -->
  </div>
</template>
<script>
import axios from "axios";
import { RotateSquare5 } from "vue-loading-spinner";
import Multiselect from "vue-multiselect";
import { gtTab, gtTabs } from "@/components/gtTabs";
import TreeView from "@/components/tree-view/index";
import Header from "@/components/pageTitle/index";
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  components: {
    RotateSquare5,
    Multiselect,
    gtTab,
    gtTabs,
    TreeView,
    Header,
  },
  data() {
    return {
      myHTML: "",
      isTouched: false,
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      ActionChangeable: null,
      printDispoLoading: false,
    };
  },
  computed: {
    canConfirm() {
      if (this.$store.state.profile.permissions.daftarusulpermanen_confirm) {
        return this.$store.state.profile.permissions.daftarusulpermanen_confirm
          .create;
      }
      return false;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
    isInvalidNamaFolder() {
      return (
        this.dropdownNamaFolder.isTouched &&
        this.dropdownNamaFolder.value.length === 0
      );
    },
    canDispo() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return this.$store.state.profile.permissions.disposition_in.create;
      }
      return false;
    },
    treeData() {
      return this.$store.state.daftarusulpermanen.detail.tracking !== undefined
        ? this.$store.state.daftarusulpermanen.detail.tracking
        : {};
    },
    items() {
      var data = [];
      if (this.$store.state.daftarusulpermanen.items) {
        for (
          let index = 0;
          index < this.$store.state.daftarusulpermanen.items.items.length;
          index++
        ) {
          let element = this.$store.state.daftarusulpermanen.items.items[index];
          data[index] = {
            id: element.id,
            agenda_number: element.agenda_number,
            nomor_surat: element.nomorsurat,
            subject: element.subject,
            sifat: element.sifat,
            tanggal_diterima: element.tanggal_diterima,
            redaksi: element.redaksi,
            asal_surat: element.asalsurat,
            confirmation: element.confirmation,
            confirm: element.confirm,
            close: element.close,
          };
        }
      }
      return data;
    },

    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.daftarusulpermanen;
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },
    tanggalsurat() {
      let text = "-";
      if (this.state.detail != undefined) {
        text = this.formatDate(this.state.detail.tanggal_surat);
      }
      return text;
    },
    tanggalditerima() {
      let text = "-";
      if (this.state.detail != undefined) {
        if (this.state.detail.tanggalditerima == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.tanggalditerima);
        }
      }
      return text;
    },
    tanggalselesai() {
      let text = "-";
      if (this.state.detail != undefined) {
        if (this.state.detail.tanggal_selesai == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.tanggal_selesai);
        }
      }
      return text;
    },

    dataPenerima() {
      var data = [];
      const penerimas = this.state.detail.penerima;
      penerimas.forEach(function (value, i) {
        // data[i] =
        //   {
        //     "text" : "- " + value.text,
        //     "style" : "header4"
        //   };

        data[i] = {
          table: {
            body: [
              [{ text: value.text }, {}],
              // [{}]
            ],
          },
        };
      });
      return data;
    },

    dataIntruksi() {
      var data = [];
      const intruksi = this.state.detail.intruksi;
      intruksi.forEach(function (value, i) {
        // data[i] = {
        //   "text" : "- " + value.text,
        //   "style" : "header4",
        // };
        data[i] = {
          table: {
            body: [
              ["Col1", "Col2", "Col3"],
              ["1", "2", "3"],
              ["1", "2", "3"],
            ],
          },
        };
      });
      return data;
    },

    urlLembarDispo() {
      return (
        this.baseUrl +
        "/daftarusulpermanen/" +
        this.$route.params.id +
        "/disposition_sheet"
      );
    },
  },
  data() {
    return {
      payload: {
        id_surat: this.$route.params.id,
        namafolder: [],
      },
      dropdownNamaFolder: {
        isTouch: false,
        namafolderList: [],
        value: [],
        isLoadingNamaFolder: false,
      },
    };
  },
  mounted() {
    this.detailLoad();
    this.getAllReference();
    // this.getUserDispo(query);
  },
  methods: {
    downloadFileForce(url) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    getAllReference() {
      var query = "";
      this.namafolder(query);
    },
    detailLoad() {
      const state = {
        loaded: false,
      };
      const user = localStorage.getItem("user");
      this.$store.commit("daftarusulpermanen/STATE", state);
      this.$store.dispatch(
        "daftarusulpermanen/getdaftarusulpermanenfolderrsuratById",
        this.$route.params
      );
    },
    onClose() {
      this.isVisible = false;
    },
    onTouchNamaFolder() {
      this.dropdownNamaFolder.isTouched = true;
    },
    onTouch() {
      this.isTouched = true;
    },
    onChangeNamaFolder(value) {
      this.dropdownNamaFolder.value = value;
    },
    namafolder(query) {
      this.dropdownNamaFolder.isLoadingNamaFolder = true;
      axios
        .get(`daftarfolder/list?s=${query}&u=${this.$route.params.unit}`)
        .then((res) => {
          this.dropdownNamaFolder.namafolderList = res.data.items;
          this.dropdownNamaFolder.isLoadingNamaFolder = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    submit() {
      const payload = {
        id_surat: this.$route.params.id,
        nama_folder: parseInt(this.payload.namafolder.value),
      };
      // const data = JSON.stringify(this.payload);
      const data = payload;
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("daftarusulpermanen/submitMoveBerkas", data);
          } else {
            this.$store.dispatch("daftarusulpermanen/submitMoveBerkas", data);
          }
        }
      });
    },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "cancel") {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    checkExtensi(file) {
      // /[.]/.exec(file) ? /[^.]+$/.exec(file) : undefined;
      // if (
      //   /[^.]+$/.exec(file)[0] === "docx" ||
      //   /[^.]+$/.exec(file)[0] === "doc" ||
      //   /[^.]+$/.exec(file)[0] === "xls"
      // ) {
      if (
        file.includes("docx") ||
        file.includes("doc") ||
        file.includes("xls")
      ) {
        return false;
      } else {
        return true;
      }
    },
    newTab(file) {
      window.open(file, file);
    },
    goDisposisi() {
      this.$store.dispatch(
        "daftarusulpermanen/onDisposisi",
        this.$route.params
      );
    },
    goBack() {
      this.$router.push("/daftarusulpermanen");
    },
    goTandaiAtasan() {
      const data = {
        id: this.$route.params.id,
        flag_atasan: 1,
      };
      // const data = JSON.stringify(payload);
      this.$store.dispatch("daftarusulpermanen/submitTandaiAtasan", data);
    },

    prev(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "daftarusulpermanen",
          id: this.$route.params.id,
          file: string,
        },
      });
      window.open(routeData.href, "_blank");
    },
    downloadDocument(filepath, filename) {
      axios
        .post("daftarusulpermanen/assets/single", { filepath: filepath })
        .then((res) => {
          const linkSource = res.data;
          const downloadLink = document.createElement("a");
          const fileName = filename;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadForce(url) {
      const downloadLink = document.createElement("a");

      downloadLink.href = url;
      downloadLink.target = "_blank";
      downloadLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    downloadDisposition(filepath, filename) {
      axios
        .post("disposition_in/assets/single", { filepath: filepath })
        .then((res) => {
          const linkSource = res.data;
          const downloadLink = document.createElement("a");
          const fileName = filename;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // prev(string) {
    //   let routeData = this.$router.resolve({name:'preview.index',
    //     params:{
    //       document_type:'daftarusulpermanen',
    //       id:this.$route.params.id,
    //       file:string
    //     }
    //     });
    //     window.open(routeData.href, "_blank");
    // },

    goConfirm() {
      this.$store.dispatch("daftarusulpermanen/onConfirm");
    },
    goSelesai() {
      this.$store.dispatch("daftarusulpermanen/onClose");
    },
    goSend() {
      this.$store.dispatch(
        "daftarusulpermanen/goSend",
        JSON.stringify({ document: this.$route.params.id })
      );
    },

    goReturn() {
      this.$store.dispatch("daftarusulpermanen/onReturn");
    },
    approve(val) {
      let payload = {
        document: this.$route.params.id,
        approval: val,
        message: this.myHTML,
      };
      this.$store.dispatch(
        "daftarusulpermanen/approveDoc",
        JSON.stringify(payload)
      );
    },
  },
  watch: {
    $route: function (search) {
      this.detailLoad();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
.normal-background {
  background-color: #fff !important;
}
</style>